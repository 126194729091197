
@media screen and (max-width:650px) {
    #order1{
        order: 1;
    }
    #order2{
        order: 2;
    }
    img{
        width: 100%;
    }
}
hr{
    width: 100%;
}