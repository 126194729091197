
.Banner{
    background-color: #012A55;
    color: white;
    width: 100%;
    
}
.CustomLogoBannerImg{
    width: 500px;
}

@media only screen and (max-width:650px) {
    .CustomLogoBannerImg{
        width: 100%;
    }
}