.input{
    width: 300px;
    border-color: #7D8284;
}
.input2{
    width: 650px;
    border-color: #7D8284;
}
#text_area{
    border-color: #7D8284;
}
#text_area:hover{
    border-color: black;
}
@media only screen and (max-width:650px) {
    .input{
        padding: 0px;
        width: 100vw;
    }
    .input2{
        width: 100vw;
    }
}